<template>

  <KIcon
    icon="language"
    style="top: 0; width: 24px; height: 24px"
    :color="$themeTokens.textInverted"
  />

</template>


<script>

  export default {
    name: 'LanguagesIcon',
  };

</script>


<style lang="scss" scoped></style>
